@import 'variables/colors.scss';
@import 'variables/breakpoints.scss';

.cards {
    gap: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 2rem 4rem;

    @include xxl {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @include lg {
        grid-template-columns: 1fr 1fr;
        margin: 1rem;
    }

    @include sm {
        grid-template-columns: 1fr;
    }

    .cardType {
        position: absolute;
        padding: 5px 30px;
        top: 30px;
        right: 0;
        background-color: $orange;
        box-shadow: 0.05rem 0.1rem 0.3rem -0.03rem rgba(0, 0, 0, 0.45);

        span {
            color: white;
            font-weight: 500;
        }
    }

    a {
        text-decoration: none;
        color: rgba(38, 38, 38, 0.789);
        font-weight: 500;
    }

    .card {
        border-radius: 0.2rem;
        box-shadow: 0.05rem 0.1rem 0.3rem -0.03rem rgba(0, 0, 0, 0.45);
        cursor: pointer;
        position: relative;
        overflow: hidden;

        .estitorState {
            position: absolute;
            border-bottom-right-radius: 0.2rem;
            left: 0;
            top: 0;
            width: 30px;
            height: 30px;
            background-color: white;
            display: flex;
            margin: auto;
            justify-content: center;
            align-items: center;
        }

        .cardPhotoWrapper {
            height: 200px;

            > img {
                border-top-left-radius: 0.3rem;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .location {
            display: flex;
            margin: 1rem auto;
            width: fit-content;

            img {
                width: 1.5rem;
            }
        }

        .bottom {
            align-items: center;
            display: flex;
            color: rgb(107 107 107);
            font-size: calc(0.7rem + 0.3vw);

            .bottomLeft {
                padding: 10px;
                gap: 0.3em;
                align-items: center;
                display: flex;
                background-color: #cfcfcf2b;

                svg {
                    font-size: 1.2em;
                }

                span {
                    white-space: nowrap;
                }
            }

            .price {
                padding: 10px 0;
                background-color: #ebeaead4;
                flex-grow: 1;
                height: 100%;
                display: flex;
                justify-content: center;
                font-weight: 500;
                color: rgba(53, 53, 53, 0.789);
                width: 10%;
            }

            img {
                width: 32px;
                height: 29px;
            }
        }
    }
}
