@import "./variables/colors.scss";
@import "./variables/breakpoints.scss";


.loginForm {
    width: 600px;
    max-width: calc(100% - 40px);
    margin: 100px auto;
    padding: 0 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 45px 19px rgb(171 169 171 / 51%);
}