.filter {
    max-width: 14000px;
    margin: auto;
    width: 92%;
}
.titleWrapper {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    align-items: center;
    margin-top: 3rem;

    .titleItemsWrapper {
        grid-column: 1 / span 8;
        display: grid;
        grid-template-columns: 8% 18% 10% 10% 20% 34%;
        align-items: center;

        @media (max-width: 1024px) {
            grid-column: 1 / span 6;
            grid-template-columns: 35% repeat(4, 15%);
        }

        .title {
            border-right: 2px solid #d9d5d5;
            text-align: center;

            @media (max-width: 1024px) {
                font-size: 14px;
            }
        }

        p {
            margin: 0px;
        }
    }
}

.tabWrapper {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    align-items: center;
    border: 1px solid #000831;
    border-radius: 10px;
    padding: 1rem 0;

    .tabItemsWrapper {
        grid-column: 1 / span 8;
        display: grid;
        grid-template-columns: 8% 18% 10% 10% 20% 34%;
        align-items: center;
        text-align: center;

        @media (max-width: 1024px) {
            grid-column: 1 / span 6;
            grid-template-columns: 35% repeat(4, 15%);
        }
    }

    .tabs {
        padding: 0 0.5rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @media (max-width: 1024px) {
            font-size: 14px;
        }
    }

    .buttonsWrapper {
        grid-column: 9 / 11;
        display: flex;
        justify-content: flex-end;
        padding: 5px;
        @media (max-width: 768px) {
            grid-column: 7 / 10;
        }

        span {
            @media (max-width: 1024px) {
                font-size: 14px;
            }
        }
    }
}
