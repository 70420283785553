$breakpoints: (
    'xxs': 400px,
    'xs': 480px,
    'sm': 540px,
    'md': 720px,
    'lg': 834px,
    'xl': 1100px,
    'xxl': 1220px,
);

@mixin xxs {
    @media(max-width:map-get($breakpoints, 'xxs')) {
        @content
    }
}

@mixin xs {
    @media(max-width:map-get($breakpoints, 'xs')) {
        @content
    }
}

@mixin sm {
    @media(max-width:map-get($breakpoints, 'sm')) {
        @content
    }
}

@mixin md {
    @media(max-width:map-get($breakpoints, 'md')) {
        @content
    }
}

@mixin lg {
    @media(max-width:map-get($breakpoints, 'lg')) {
        @content
    }
}

@mixin xl {
    @media(max-width:map-get($breakpoints, 'xl')) {
        @content
    }
}

@mixin xxl {
    @media(max-width:map-get($breakpoints, 'xxl')) {
        @content
    }
}