.carouselImage {
    position: relative;
    margin: 1rem !important;
    max-width: fit-content;

    img {
        object-fit: cover;
    }

    .trash {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.4rem;
        background-color: white;
        border-bottom-left-radius: 2px;
        border: 1px solid red;
        border-top-width: 0;
        border-right-width: 0;
        cursor: pointer;
    }
}

.carouselBtn {
    align-self: center;
    background: black;
    border: none;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    font-size: 20px;
    height: 30px;
    line-height: 1;
    text-align: center;
    width: 30px;
}
