.menuToggle {
    display: none;
    /* position: absolute;
 top: 30px;
 left: 350px; */
}

/* Left */

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
    width: 0px;
    height: 100vh;
    background: #020321;
    border-right: 2px solid #020321;
    opacity: 1;
    transform: translateX(-250px);
    transition: all 0.8s ease;
}

/* Right */

.right {
    position: relative;
    padding: 80px 30px 30px;
    display: flex;
    align-items: center;
    transition: all 0.8s ease;
    margin-left: 0;
    width: 100%;
}

/* If Checked */

.menuToggle:checked~.sidebar {
    width: 250px;
    transform: translateX(0);
}

.menuToggle:checked~.right {
    width: calc(100% - 250px);
    margin-left: 250px;
}

/* For Navbar */

.logo {
    display: block;
    padding: 20px 0px;
    text-align: center;

    img {
        width: 60%;
        height: 60%;
    }
}

.navItems {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.navItems a {
    display: block;
    padding: 20px 0px;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    transition: all 0.4s ease;
    color: white
}

.navItems a:hover,
.navItems a.active {
    background: #073f39;
}



.content {
    width: 100%;
    height: 100%;
}

/* Toggle bar */

.toggleIcon {
    position: absolute;
    top: 24px;
    left: 30px;
    cursor: pointer;
}

.toggleIcon .line {
    width: 24px;
    height: 3px;
    background-color: #020321;
    margin: 6px 0;
    display: block;
    border-radius: 8px;
}