@import 'variables/breakpoints.scss';

.formNewAccommodation {
    height: 100%;
    width: 1000px;
    max-width: calc(100% - 20px);
    border-radius: 20px;
    margin: auto;

    .radioButtons {
        display: flex;
        flex-wrap: wrap;

        @include sm {
            justify-content: center;
        }
    }
}
