@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,700;1,600&display=swap');
@import '~bootstrap/scss/bootstrap';
@import 'react-alice-carousel/lib/scss/alice-carousel.scss';

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    font-family: 'Raleway', sans-serif;
    background: #f2f2f2;
    z-index: 1;
}

#root {
    display: flex;
    width: 100%;
}

a {
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
}

.header {
    width: fit-content;
    margin: auto;
}

.noFound {
    margin: 8rem auto;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 600;
    color: #555352;
    font-size: 1.2rem;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.spin {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}