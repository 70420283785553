@import 'variables/colors.scss';

.paginationBttns {
    ul {
        height: 40px;
        list-style: none;
        display: flex;
        justify-content: center;
        padding: 0;

        .active a {
            color: white;
            background-color: $orange;
        }

        a {
            padding: 5px 10px 7px;
            margin: 8px;
            border-radius: 50%;
            border: 1px solid #d4cfcb3d;
            color: #000000;
            font-weight: bold;
            cursor: pointer;
            background-color: #d4cfcb3d;

            &:hover {
                color: white !important;
                background-color: $orange;
            }

        }

    }

}